import React, { useEffect, useState } from 'react';
import { ModalDialog } from '../modals';
import { Button } from '../buttons';
import { FormattedMessage } from 'react-intl';
import { intl } from '../../stories/IntlWrapper';
import {
  trackUserProfileModalDismissed,
  trackUserProfileModalDisplayed,
  trackUserProfileResponseCompleted,
} from '../../helpers/analytics';
import featureFlagService from '../../helpers/feature-flags';
import { useUpdateUserProfile, useUserProfile } from '../../services/User';
import { User } from '../../graphql/operations';
import { sub } from 'date-fns/sub';

export const LOCAL_STORAGE_KEY = 'dismissedUserProfileModalAt';

const traits = {
  workType: {
    title: intl.formatMessage({
      defaultMessage: 'What kind of work do you do?',
    }),
    subtitle: intl.formatMessage({
      defaultMessage: 'Help us enhance your meeting transcripts and experience',
    }),
    options: [
      {
        key: 'education',
        value: intl.formatMessage({ defaultMessage: 'Teacher / Student' }),
      },
      {
        key: 'marketing',
        value: intl.formatMessage({ defaultMessage: 'Marketing' }),
      },
      {
        key: 'project_management',
        value: intl.formatMessage({ defaultMessage: 'Project Management' }),
      },
      {
        key: 'it_services',
        value: intl.formatMessage({ defaultMessage: 'IT Services' }),
      },
      {
        key: 'engineering',
        value: intl.formatMessage({
          defaultMessage: 'Engineering / Development',
        }),
      },
      {
        key: 'sales',
        value: intl.formatMessage({ defaultMessage: 'Sales / Success' }),
      },
      {
        key: 'operations',
        value: intl.formatMessage({ defaultMessage: 'Operations' }),
      },
      {
        key: 'legal',
        value: intl.formatMessage({ defaultMessage: 'Legal / Finance' }),
      },
      {
        key: 'hr',
        value: intl.formatMessage({ defaultMessage: 'HR / People' }),
      },
      {
        key: 'product',
        value: intl.formatMessage({ defaultMessage: 'Product' }),
      },
      {
        key: 'analytics',
        value: intl.formatMessage({
          defaultMessage: 'Analytics / Data Science',
        }),
      },
      {
        key: 'customer_support',
        value: intl.formatMessage({ defaultMessage: 'Customer Support' }),
      },
      {
        key: 'design',
        value: intl.formatMessage({ defaultMessage: 'Design / UX' }),
      },
      {
        key: 'agile',
        value: intl.formatMessage({
          defaultMessage: 'Agile Coach / Scrum Master',
        }),
      },
    ] as const,
  },
  workRole: {
    title: intl.formatMessage({
      defaultMessage: 'What is your role?',
    }),
    subtitle: intl.formatMessage({
      defaultMessage: 'Select the role that best describes your work',
    }),
    options: [
      {
        key: 'individual_contributor',
        value: intl.formatMessage({ defaultMessage: 'Individual Contributor' }),
      },
      {
        key: 'team_manager',
        value: intl.formatMessage({ defaultMessage: 'Team manager' }),
      },
      {
        key: 'contractor',
        value: intl.formatMessage({
          defaultMessage: 'Contractor/Freelancer/Solopreneur',
        }),
      },
      {
        key: 'executive',
        value: intl.formatMessage({ defaultMessage: 'Executive (C-level)' }),
      },
      {
        key: 'director',
        value: intl.formatMessage({ defaultMessage: 'Director / VP' }),
      },
    ] as const,
  },
} as const;

const traitsWithoutRoles: TraitValue[] = ['education'];

type WorkType = (typeof traits.workType.options)[number]['key'];
type WorkRole = (typeof traits.workRole.options)[number]['key'];
type Trait = keyof typeof traits;
type TraitValue = WorkType | WorkRole;

interface ProfileBuilderSlideProps {
  trait?: Trait;
  value: TraitValue;
  onChange: (value: TraitValue) => void;
}

export const ProfileBuilderSlide: React.FunctionComponent<
  ProfileBuilderSlideProps
> = ({ trait, value, onChange }) => {
  const currentTrait = traits[trait ?? 'workType'];
  return (
    <div className="flex flex-col justify-between gap-4">
      <h1 className="font-bold text-2xl text-neutral-primary">
        {currentTrait.title}
      </h1>
      <p className="text-neutral-secondary">{currentTrait.subtitle}</p>
      {currentTrait.options && (
        <div className="flex flex-wrap gap-2">
          {currentTrait.options.map((option) => (
            <Button
              variant={
                value === option.key ? 'neutral-primary' : 'neutral-secondary'
              }
              key={option.key}
              onClick={() => onChange(option.key)}
            >
              {option.value}
            </Button>
          ))}
        </div>
      )}
    </div>
  );
};

interface ConfirmationSlideProps {
  onFinish: () => void;
}

const ConfirmationSlide: React.FunctionComponent<ConfirmationSlideProps> = ({
  onFinish,
}) => {
  useEffect(() => {
    trackUserProfileResponseCompleted();
  }, []);

  return (
    <div className="flex flex-col justify-between gap-4">
      <h1 className="font-bold text-2xl text-neutral-primary">
        <FormattedMessage defaultMessage="Thanks for that." />
      </h1>
      <p className="text-neutral-secondary">
        <FormattedMessage defaultMessage="Your responses will help us personalize recommendations, meeting insights and your workspace experience" />
      </p>
      <Button variant="neutral-primary" onClick={onFinish}>
        <FormattedMessage defaultMessage="Finish" />
      </Button>
    </div>
  );
};

interface ProfileBuilderProps {
  profile: Pick<User, 'workType' | 'workRole'>;
  onFinish: () => void;
  onNext?: () => void;
}

export const ProfileBuilder: React.FunctionComponent<ProfileBuilderProps> = ({
  profile = {},
  onFinish,
  onNext,
}) => {
  const allTraitKeys = Object.keys(traits) as Trait[];
  const [currentTraitKey, setCurrentTraitKey] = useState<Trait | undefined>(
    allTraitKeys[0]
  );

  const [selectedTraits, setSelectedTraits] =
    useState<Pick<User, 'workType' | 'workRole'>>(profile);

  const updateMutation = useUpdateUserProfile();

  if (updateMutation.error) throw updateMutation.error;

  const updateProfile = async (trait: Trait, value: TraitValue) => {
    await updateMutation.request({
      input: trait === 'workType' ? { workType: value } : { workRole: value },
    });
  };

  const currentTraitIndex = Object.keys(traits).findIndex(
    (key) => key === currentTraitKey
  );
  const traitsCount = Object.keys(traits).length;

  if (currentTraitKey === undefined) {
    return <ConfirmationSlide onFinish={onFinish} />;
  }

  return (
    <div className="flex flex-col justify-between gap-4">
      <ProfileBuilderSlide
        trait={currentTraitKey}
        value={(selectedTraits[currentTraitKey] ?? '') as TraitValue}
        onChange={(value) => {
          setSelectedTraits(() => {
            updateProfile(currentTraitKey, value);
            return Object.assign({}, selectedTraits, {
              [currentTraitKey]: value,
            });
          });
        }}
      />
      <div className="flex justify-between">
        <Button
          variant="neutral-primary"
          disabled={!selectedTraits[currentTraitKey]}
          onClick={() => {
            setCurrentTraitKey(() => {
              onNext?.();
              if (
                traitsWithoutRoles.includes(
                  (selectedTraits.workType ?? '') as TraitValue
                )
              )
                return undefined;
              return Object.keys(traits).at(currentTraitIndex + 1) as Trait;
            });
          }}
        >
          {currentTraitIndex < traitsCount ? (
            <FormattedMessage defaultMessage="Next" />
          ) : (
            <FormattedMessage defaultMessage="Finish" />
          )}
        </Button>
      </div>
    </div>
  );
};

export const useShouldOpenProfileModal = (): boolean => {
  const userProfile = useUserProfile();

  const dismissedAt = localStorage.getItem(LOCAL_STORAGE_KEY);
  const dissmissedTime = dismissedAt ? Number.parseInt(dismissedAt, 10) : null;

  const previouslyDismissedModal =
    dissmissedTime && sub(new Date(), { days: 30 }).getTime() <= dissmissedTime;

  const userProfileModalFeatureFlag = featureFlagService.showUserProfileModal();
  const workType = userProfile?.workType;
  const workRole = userProfile?.workRole;
  const hasFullProfile = !!workType && !!workRole;

  return (
    userProfileModalFeatureFlag && !previouslyDismissedModal && !hasFullProfile
  );
};

export const UserProfileModal: React.FunctionComponent = () => {
  const shouldOpen = useShouldOpenProfileModal();
  const [isOpen, setIsOpen] = useState(shouldOpen);
  const profile = useUserProfile();

  useEffect(() => {
    if (isOpen) {
      trackUserProfileModalDisplayed();
    }
  }, [isOpen]);

  return (
    <ModalDialog
      open={isOpen}
      onClose={() => {
        trackUserProfileModalDismissed();
        localStorage.setItem(LOCAL_STORAGE_KEY, String(new Date().getTime()));
        setIsOpen(false);
      }}
    >
      <ProfileBuilder
        profile={profile}
        onFinish={() => {
          trackUserProfileResponseCompleted();
          setIsOpen(false);
        }}
      />
    </ModalDialog>
  );
};

export default ProfileBuilder;
